<template>
    <div class="bg-gray-100 p-2 flex items-center w-full flex-1 rounded-md border-2 justify-between border-transparent transition-all duration-400" @click.stop="$emit('handleSelect',data)" :class="activeElement && activeElement.id === data.id && 'border-primary'">
        <div class="flex flex-col flex-grow-0">
            <span class="font-semibold block text-sm capitalize max-w-xs truncate" :title="data.label">{{ data.label }}</span>
            <span class="text-xs  max-w-xs truncate block" :title="data.name">{{ data.name }}</span>
        </div>
        <span class="px-2 py-0.5 rounded-full flex-shrink-0  bg-gray-300 text-2xs font-semibold capitalize"> {{ data.is_custom ? "custom" : "system provided" }} </span>
    </div>
</template>

<script>
export default {
    name: "block-list-item",
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        activeElement:{
            type: Object,
            default: () => {}
        }
    },
    components: {},
    methods: {},
    computed: {},
};
</script>

<style style="scss" scoped></style>
